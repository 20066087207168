import de from './locales/de.json'
import { createI18n } from 'vue-i18n'
import { datetimeFormats, numberFormats } from './helpers/i18nFormats'

const CONFIG = {
  legacy: false,
  locale: 'de',
  localeMessageDirection: './locales',
  messages: {
    de,
  },
  datetimeFormats: datetimeFormats,
  numberFormats: numberFormats,
}

export default defineI18nConfig(() => CONFIG)
export const i18n = createI18n(CONFIG)
